import {Component, ViewChild} from '@angular/core';
import {MatStep} from "@angular/material/stepper";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AccountService} from "../shared/services/account.service";
import {AssamRestErrorResponse} from "../shared/dm";

export namespace AccountDeletionForm {
  export const FIELD_REASON = "reason"
}

export namespace AccountDeletionConfirmationForm {
  export const FIELD_IRREVERSIBLE_OPERATION_CONFIRMATION = "confirmIrreversibleDeleteOp"
}

@Component({
  selector: 'account-deletion',
  templateUrl: './account-deletion.component.html',
  styleUrls: ['./account-deletion.component.scss']
})
export class AccountDeletionComponent {

  constructor(
    private readonly accountService: AccountService
  ) {}

  @ViewChild("stepRequest") stepRequest!: MatStep
  @ViewChild("stepRequestConfirmation") stepRequestConfirmation!: MatStep
  @ViewChild("stepRequestResult") stepRequestResult!: MatStep

  sendingRequest = false
  AccountDeletionForm = AccountDeletionForm
  AccountDeletionConfirmationForm = AccountDeletionConfirmationForm

  requestFormControl = new FormGroup({
    [AccountDeletionForm.FIELD_REASON]: new FormControl(null),
  })

  confirmationFormControl = new FormGroup({
    [AccountDeletionConfirmationForm.FIELD_IRREVERSIBLE_OPERATION_CONFIRMATION]: new FormControl(null, Validators.requiredTrue),
  })

  requestFailureMsg?: string

  goToConfirmation() {
    this.stepRequest.completed = true
    this.stepRequestConfirmation.select()

    // Focus button instead of stepper (looks weird in the UI)
    setTimeout(() => this.removeFocus())
  }

  private removeFocus() {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  deleteAccountPermanently() {
    this.sendingRequest = true
    const reason = this.requestFormControl.value[AccountDeletionForm.FIELD_REASON]

    this.accountService.requestAccountDeletion(reason).subscribe({
      next: () => {
        this.activateRequestResultStep()
      },
      error: (err) => {
        this.activateRequestResultStep()

        this.requestFailureMsg = $localize `:@@errMsg.accountDeletion.requestFailed:We're sorry, but we're unable to process your account deletion request at the moment. Please try again later.`

        const asammErr: AssamRestErrorResponse = err.error
        if (asammErr?.message) {
          this.requestFailureMsg += ` \n\nError: ${asammErr?.message}`
        }
        if (asammErr?.incidentId) {
          this.requestFailureMsg += ` [incident ID: ${asammErr?.incidentId}]`
        }
      }
    })
  }

  private activateRequestResultStep() {
    this.sendingRequest = false;
    this.stepRequestConfirmation.completed = true;

    this.stepRequestResult.select();
    this.stepRequestResult.completed = true;

    // Focus button instead of stepper (looks weird in the UI)
    setTimeout(() => this.removeFocus())
  }
}
