import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {ListOption} from "../dm";
import {Observable} from "rxjs";
import {AccountService} from "../services/account.service";
import {Injectable} from "@angular/core";

@Injectable()
export class LanguagesResolver implements Resolve<ListOption[]> {
  constructor(
    private readonly accountService: AccountService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ListOption[]> {
    return this.accountService.loadLanguages()
  }
}
