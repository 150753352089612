// TODO verify with Petr: voucherDetail.premiumItem.subscriptionSource can have these values
import {LocusStore} from "../shared/dm";
import SubscriptionPlan = LocusStore.SubscriptionPlan;
import SubscriptionSource = LocusStore.SubscriptionSource;


export enum TransitionMode {
  NO_TRANSITION,
  GOLD_OT_SILVER_OT,
  SILVER_OT_GOLD_OT,
  SAME_TIER_OT_OT,
  SILVER_SUBS_GOLD_OT,
  SILVER_OT_GOLD_SUBS,
  SAME_TIER_OT_SUBS,
  GOLD_OT_SILVER_SUBS,
  UNDEFINED
}

export interface PremiumSourcePlan {
  source: SubscriptionSource
  subscriptionPlan: SubscriptionPlan
}

export namespace SubscriptionUtils {
  export function resolveTransitionMode (
    premiumNew: PremiumSourcePlan,
    premiumOld?: PremiumSourcePlan
  ): TransitionMode {
    if (premiumOld == null) {
      return TransitionMode.NO_TRANSITION
    }

    // prepare parameters
    const premiumNewOneTime = premiumNew.source == SubscriptionSource.STORE_IN_APP
      || premiumNew.source == SubscriptionSource.LOCOIN_PURCHASE
      || premiumNew.source == SubscriptionSource.VOUCHER
    const premiumOldOneTime = premiumOld.source == SubscriptionSource.STORE_IN_APP
      || premiumOld.source == SubscriptionSource.LOCOIN_PURCHASE
      || premiumOld.source == SubscriptionSource.VOUCHER

    // test combinations
    if (premiumOld.subscriptionPlan == SubscriptionPlan.LOCUS_MAP_GOLD
      && premiumOldOneTime
      && premiumNew.subscriptionPlan == SubscriptionPlan.LOCUS_MAP_SILVER
      && premiumNewOneTime
    ) {
      // Gold one-time > Silver one-time
      return TransitionMode.GOLD_OT_SILVER_OT
    } else if (premiumOld.subscriptionPlan == SubscriptionPlan.LOCUS_MAP_SILVER
      && premiumOldOneTime
      && premiumNew.subscriptionPlan == SubscriptionPlan.LOCUS_MAP_GOLD
      && premiumNewOneTime
    ) {
      // Silver one-time > Gold one-time
      return TransitionMode.SILVER_OT_GOLD_OT
    } else if (premiumOld.subscriptionPlan == premiumNew.subscriptionPlan
      && premiumOldOneTime
      && premiumNewOneTime
    ) {
      // same tier, one-time > one-time
      return TransitionMode.SAME_TIER_OT_OT
    } else if (premiumOld.subscriptionPlan == SubscriptionPlan.LOCUS_MAP_SILVER
      && premiumOld.source == SubscriptionSource.STORE_SUBSCRIPTION
      && premiumNew.subscriptionPlan == SubscriptionPlan.LOCUS_MAP_GOLD
      && premiumNewOneTime
    ) {
      // Silver subs > Gold one-time
      return TransitionMode.SILVER_SUBS_GOLD_OT
    } else if (premiumOld.subscriptionPlan == SubscriptionPlan.LOCUS_MAP_SILVER
      && premiumOldOneTime
      && premiumNew.subscriptionPlan == SubscriptionPlan.LOCUS_MAP_GOLD
      && premiumNew.source == SubscriptionSource.STORE_SUBSCRIPTION
    ) {
      // Silver one-time > Gold subs
      return TransitionMode.SILVER_OT_GOLD_SUBS
    } else if (premiumOld.subscriptionPlan == premiumNew.subscriptionPlan
      && premiumOldOneTime
      && premiumNew.source == SubscriptionSource.STORE_SUBSCRIPTION
    ) {
      // same tier, one-time > subs
      return TransitionMode.SAME_TIER_OT_SUBS
    } else if (premiumOld.subscriptionPlan == SubscriptionPlan.LOCUS_MAP_GOLD
      && premiumOldOneTime
      && premiumNew.subscriptionPlan == SubscriptionPlan.LOCUS_MAP_SILVER
      && premiumNew.source == SubscriptionSource.STORE_SUBSCRIPTION
    ) {
      // Gold one-time > Silver subs
      return TransitionMode.GOLD_OT_SILVER_SUBS
    } else {
      throw Error("Unknown transition")
    }
  }

}
