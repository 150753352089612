import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {LocusStoreService} from "../../shared/services/locus-store.service";
import {MatStep} from "@angular/material/stepper";
import {MatButton} from "@angular/material/button";
import {MSG_VOUCHER_REDEMPTION_FAILURE} from "../redeem-voucher.component";
import {LocusStore} from "../../shared/dm";
import {SubscriptionUtils, TransitionMode} from "../../subscription/subscription-utils";
import {DatePipe, DecimalPipe} from "@angular/common";
import {TRANSITION_MESSAGES} from "./transition-messages";
import PrePurchaseResultParams = LocusStore.PrePurchaseResultParams;
import VoucherDetail = LocusStore.VoucherDetail;
import PremiumItem = LocusStore.PremiumItem;
import PrePurchaseSubsParams = LocusStore.PrePurchaseSubsParams;
import SubscriptionPlan = LocusStore.SubscriptionPlan;

interface InfoRow {
  icon: string,
  label: string,
  value: string
}

@Component({
  selector: 'redeem-voucher-premium',
  templateUrl: './redeem-voucher-premium.component.html',
  styleUrls: ['./redeem-voucher-premium.component.scss'],
  providers: [
    DatePipe,
    DecimalPipe
  ]
})
export class RedeemVoucherPremiumComponent implements OnInit {
  constructor(
    private readonly locusStoreService: LocusStoreService,
    private readonly datePipe: DatePipe,
    private readonly decimalPipe: DecimalPipe
  ) {}

  currentSubscriptionInfoRows: InfoRow[] = []
  newSubscriptionInfoRows: InfoRow[] = []

  @Input() voucherDetail!: VoucherDetail
  @Input() prePurchaseResult!: PrePurchaseResultParams
  @Input() premiumItems!: PremiumItem[]
  @Input() userProfile!: LocusStore.UserProfile
  @Output() startNewVoucherRedemption = new EventEmitter<void>()

  @ViewChild("stepRedemptionResult") stepRedemptionResult!: MatStep
  @ViewChild("headingElement") headingHtmlElement!: HTMLElement

  loading = false

  redeemptionResultSuccessMsg?: string
  redeemptionResultFailureMsg?: string

  currentSubsParams?: PrePurchaseSubsParams
  currentPremiumItem?: PremiumItem
  newSubsParams!: PrePurchaseSubsParams
  newPremiumItem!: PremiumItem
  transitionMode!: TransitionMode

  readonly TransitionMode = TransitionMode

  ngOnInit(): void {
    const mainActiveSubsProdId: string | undefined = this.userProfile.premiumSubscriptions[0]?.productId
    this.currentSubsParams = this.prePurchaseResult.currentSubsParams
      .find((p) => p.productId == mainActiveSubsProdId)
    this.currentPremiumItem = this.premiumItems.find(item => item.productId === mainActiveSubsProdId)

    this.newSubsParams = this.prePurchaseResult.nextSubsParams
    this.newPremiumItem = this.premiumItems
      .find((item) => item.productId == this.prePurchaseResult.nextSubsParams.productId)!!

    this.transitionMode = SubscriptionUtils.resolveTransitionMode(
      {
        subscriptionPlan: this.newPremiumItem.subscriptionPlan,
        source: this.newPremiumItem.subscriptionSource,
      },
      (this.currentSubsParams && this.currentPremiumItem) ? {
        subscriptionPlan: this.currentPremiumItem?.subscriptionPlan,
        source: this.currentPremiumItem?.subscriptionSource,
      } : undefined
    );

    if (this.currentSubsParams) {
      // Fill in current subscription info
      this.collectInfoRows(
        this.currentSubscriptionInfoRows,
        false,
        this.currentSubsParams,
        this.currentPremiumItem!!,
        this.transitionMode
      );
    }

    // Fill in new subscription info
    this.collectInfoRows(
      this.newSubscriptionInfoRows,
      true,
      this.newSubsParams,
      this.newPremiumItem,
      this.transitionMode
    )
  }

  private activateRedemptionResultStep() {
    this.stepRedemptionResult.select()
    // Remove focus from the stepper (looks weird in the UI)
    setTimeout(() => this.removeFocus())
  }

  redeemVoucher() {
    this.loading = true
    this.activateRedemptionResultStep()

    this.locusStoreService.redeemVoucher(this.voucherDetail.voucherCode, this.prePurchaseResult.payload)
      .subscribe({
        next: (response) => {
          this.loading = false

          let premiumStr = response.subscriptionPlan.toString()
          if (response.subscriptionPlan == SubscriptionPlan.LOCUS_MAP_GOLD) {
            premiumStr = "Premium Gold"
          } else if (response.subscriptionPlan == SubscriptionPlan.LOCUS_MAP_SILVER) {
            premiumStr = "Premium Silver"
          }

          const validFrom = this.datePipe.transform(response.startTimeMillis, "short")
          const validUntil = this.datePipe.transform(response.expiryTimeMillis, "short")
          this.redeemptionResultSuccessMsg = $localize`:@@section.redeemVoucher.premium.msg.success:Done! You've successfully redeemed the Premium voucher. ${premiumStr} has been activated for your account since ${validFrom} until ${validUntil}.`
        },
        error: () => {
          this.loading = false
          this.redeemptionResultFailureMsg = MSG_VOUCHER_REDEMPTION_FAILURE
        }
      });
  }

  applyNextVoucher() {
    this.startNewVoucherRedemption.emit()
  }

  getTransitionMsg(transitionMode: TransitionMode): string | undefined {
    return TRANSITION_MESSAGES.find((m) => m.transitionMode == transitionMode)?.translatedMsg
  }

  private removeFocus() {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  // Inspired by https://github.com/asamm/locus-core/blob/15246777eda7bb76e3f0296ac1e795cfb78cf043/locusFinalBasic/src/common/map/src/com/asamm/locus/basic/features/premium/uiPurchase/ConfirmPurchaseDialog.kt#L237
  private collectInfoRows(
    infoRows: InfoRow[],
    isNewPremium: boolean,
    subsParams: PrePurchaseSubsParams,
    premiumItem: PremiumItem,
    transitionMode: TransitionMode
  ) {
    infoRows.push({
      icon: "ic_info",
      label: $localize `:@@common.title:Title`,
      value: premiumItem.name
    })

    // Currently only voucher is possible
    if (isNewPremium) {
      infoRows.push({
        icon: "ic_premium",
        label: $localize `:@@common.price:Price`,
        value: $localize `:@@common.voucher:Voucher`
      })
    }

    if (subsParams.expiryMillisOriginal == subsParams.expiryMillisTrans) {
      infoRows.push({
        icon: "ic_symbol_time",
        label: $localize `:@@common.validTill:Valid till`,
        value: this.datePipe.transform(subsParams.expiryMillisOriginal, "short")!!
      })
    } else {
      if (isNewPremium) {
        const showOriginalAndModifiedExpiration = [
          TransitionMode.GOLD_OT_SILVER_OT,
          TransitionMode.SAME_TIER_OT_OT,
          TransitionMode.SILVER_OT_GOLD_OT,
          TransitionMode.SILVER_OT_GOLD_SUBS,
          TransitionMode.SAME_TIER_OT_SUBS,
          TransitionMode.GOLD_OT_SILVER_SUBS
        ].includes(transitionMode)

        if (showOriginalAndModifiedExpiration) {
          infoRows.push({
            icon: "ic_symbol_time",
            label: $localize `:@@common.originalExpirationDate:Original expiration date`,
            value: this.datePipe.transform(subsParams.expiryMillisOriginal, "short")!!
          })

          infoRows.push({
            icon: "ic_symbol_time",
            label: $localize `:@@common.newExpirationDate:New expiration date`,
            value: this.datePipe.transform(subsParams.expiryMillisTrans, "short")!!
          })
        }
      } else {
        // Current plan
        let originalExpDateLabel
        switch (transitionMode) {
          case TransitionMode.GOLD_OT_SILVER_OT:
          case TransitionMode.SAME_TIER_OT_OT:
          case TransitionMode.SILVER_OT_GOLD_OT:
          case TransitionMode.SILVER_OT_GOLD_SUBS:
          case TransitionMode.SAME_TIER_OT_SUBS:
          case TransitionMode.GOLD_OT_SILVER_SUBS:
            originalExpDateLabel = $localize `:@@common.originalExpirationDate:Original expiration date`
            break;
          case TransitionMode.SILVER_SUBS_GOLD_OT:
            originalExpDateLabel = $localize `:@@common.nextBillingDate:Next billing date`
            break;
        }

        if (originalExpDateLabel) {
          infoRows.push({
            icon: "ic_symbol_time",
            label: originalExpDateLabel,
            value: this.datePipe.transform(subsParams.expiryMillisOriginal, "short")!!
          })
        }
      }
    }

    if (subsParams.refundedLoCoinMicros > 0) {
      infoRows.push({
        icon: "ic_info",
        label: $localize `:@@common.refundedLocoins:Refunded LoCoins`,
        value: this.decimalPipe.transform(subsParams.refundedLoCoinMicros / 1_000_000, "1.0-2")!!
      })
    }
  }
}
