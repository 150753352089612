import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {AuthenticatedUser} from "../dm";

@Injectable()
export class AuthService {
  private readonly authTokenSubject = new BehaviorSubject<undefined|string>(undefined)
  readonly authToken$ = this.authTokenSubject.asObservable()

  private readonly userSubject = new BehaviorSubject<undefined|AuthenticatedUser>(undefined)
  readonly user$ = this.userSubject.asObservable()

  updateToken(newToken: string) {
    this.authTokenSubject.next(newToken)
  }

  updateUser(user: AuthenticatedUser) {
    this.userSubject.next(user)
  }
}
