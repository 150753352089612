<h2 i18n="@@section.redeemVoucher.premium.heading">Redeem Premium voucher</h2>

<mat-stepper linear>
  <mat-step editable="false">
    <ng-template matStepLabel>
      <ng-container i18n="@@common.info">Info</ng-container>
    </ng-template>

    <div *ngIf="getTransitionMsg(transitionMode) as transitionMsg"
         class="alert alert-info">{{ transitionMsg }}</div>

    <div class="subs-container">
      <div *ngIf="currentSubscriptionInfoRows.length > 0">
        <h3 i18n="@@common.currentPlan">Current plan</h3>

        <div class="list">
          <div class="row" *ngFor="let infoRow of currentSubscriptionInfoRows">
            <div class="row-icon">
              <mat-icon [svgIcon]="infoRow.icon"></mat-icon>
            </div>
            <div class="row-content">
              <div class="row-content-label">{{ infoRow.label }}</div>
              <div class="row-content-value">{{ infoRow.value }}</div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="newSubscriptionInfoRows.length > 0">
        <!-- Title only makes sense when there is also exiting subs running -->
        <h3 *ngIf="currentSubscriptionInfoRows.length > 0"
            i18n="@@common.newPlan">New plan</h3>

        <div class="list">
          <div class="row" *ngFor="let infoRow of newSubscriptionInfoRows">
            <div class="row-icon">
              <mat-icon [svgIcon]="infoRow.icon"></mat-icon>
            </div>
            <div class="row-content">
              <div class="row-content-label">{{ infoRow.label }}</div>
              <div class="row-content-value">{{ infoRow.value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-actions">
      <button mat-flat-button
              color="primary"
              (click)="redeemVoucher()"
              i18n="@@common.action.redeem">Redeem</button>

      <button mat-flat-button
              color="accent"
              (click)="applyNextVoucher()"
              i18n="@@common.action.cancel">Cancel</button>
    </div>
  </mat-step>

  <mat-step editable="false" #stepRedemptionResult>
    <ng-template matStepLabel>
      <ng-container i18n="@@common.confirmation">Confirmation</ng-container>
    </ng-template>

    <mat-progress-bar *ngIf="loading else redemptionResultMsg"
                      mode="indeterminate">
    </mat-progress-bar>

    <ng-template #redemptionResultMsg>
      <div class="alert alert-success" *ngIf="redeemptionResultSuccessMsg">{{ redeemptionResultSuccessMsg }}</div>

      <div class="alert alert-error" *ngIf="redeemptionResultFailureMsg">{{ redeemptionResultFailureMsg }}</div>

      <div class="form-actions">
        <button #applyNextVoucherBtn mat-flat-button
                color="primary"
                i18n="@@common.action.close"
                (click)="applyNextVoucher()">Close</button>
      </div>
    </ng-template>
  </mat-step>
</mat-stepper>
