<div class="profile-picture-preview-container">
  <ng-container *ngIf="selectedFile; else currentProfilePicture">
    <h2 i18n="@@section.profilePicture.newProfilePicture">New profile picture</h2>

    <div class="alert alert-info">
      <mat-icon>info</mat-icon>
      <ng-container i18n="@@section.profilePicture.infoMsg.saveToApplyChange">Save to confirm the new profile picture</ng-container>
    </div>
  </ng-container>

  <ng-template #currentProfilePicture>
    <ng-container *ngIf="currentProfilePictureUrl">
      <h2 i18n="@@section.profilePicture.currentProfilePicture">Current profile picture</h2>
    </ng-container>
  </ng-template>

  <img [src]="currentProfilePictureUrl"
       style="display: none"
       #profilePictureImgElm
       alt="profile picture"
       (load)="profilePictureLoaded($event)">
  <canvas #profilePictureCanvasElm
          width="200"
          height="200"
          i18n-title="@@common.profilePicture"
          title="Profile picture">
    <span i18n="@@section.profilePicture.msg.canvasNotSupported">Sorry, your browser can't display the profile picture. Use a different one.</span>
  </canvas>
</div>

<div class="profile-picture-upload-container">
  <button type="button"
          mat-raised-button
          i18n="@@common.action.chooseFile"
          (click)="fileInput.click()">
    Choose file
  </button>
  <input type="file" hidden id="file"
         #fileInput
         accept="image/jpeg,image/png"
         (change)="onFileSelected($event)">
  <strong *ngIf="selectedFile">
    {{ selectedFile.name }}
  </strong>

</div>

  <div class="form-actions">
    <button *ngIf="selectedFile && !saving"
            mat-flat-button
            color="primary"
            i18n="@@common.action.save"
            (click)="updateProfilePicture()">Save</button>

    <button *ngIf="selectedFile && saving"
            mat-flat-button
            color="primary"
            i18n="@@common.action.saving"
            disabled>Saving...</button>

    <button *ngIf="!hasDefaultProfilePicture && !selectedFile && currentProfilePictureUrl && !removing"
            mat-flat-button
            color="warn"
            i18n="@@common.action.remove"
            (click)="removeProfilePicture()">Remove</button>

    <button *ngIf="!hasDefaultProfilePicture && currentProfilePictureUrl && removing"
            mat-flat-button
            color="warn"
            i18n="@@common.action.removing"
            disabled>Removing...</button>
  </div>

<div class="alert alert-info">
  <mat-icon>info</mat-icon>
  <ng-container i18n="@@section.profilePicture.msg.profilePictureVisibleToAnyone">
    Other people can see your profile picture when you share some content - photos, tracks etc.
  </ng-container>
</div>
