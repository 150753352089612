import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "./shared/services/auth.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AppMode, LocusRoute} from "./shared/dm";
import {filter, map, Observable, shareReplay, Subject, take, takeUntil} from "rxjs";
import {SettingsService} from "./shared/services/settings.service";
import {BreakpointObserver} from "@angular/cdk/layout";
import {environment} from "../environments/environment";
import {DomSanitizer, Title} from "@angular/platform-browser";
import {ROUTES} from "./app-routing.module";
import {AccountService} from "./shared/services/account.service";
import {MatIconRegistry} from "@angular/material/icon";
import {APP_VERSION, APP_VERSION_DATE} from "../version";

const PARAM_MODE = "mode"
const PARAM_MODE_ANDROID = "android" // Android WebView
const PARAM_MODE_IOS = "ios" // iOS WebView

const LOCUS_ACCOUNT = $localize `:@@common.locusAccount:Locus account`

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly settingsService: SettingsService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly title: Title,
    private readonly accountService: AccountService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    // Register additional icons
    const icons: {name: string, path: string}[] = [
      {name: "ic_info", path: "assets/icons/ic_info.svg"},
      {name: "ic_launcher_simple", path: "assets/icons/ic_launcher_simple.svg"},
      {name: "ic_premium", path: "assets/icons/ic_premium.svg"},
      {name: "ic_symbol_time", path: "assets/icons/ic_symbol_time.svg"}
    ]
    icons.forEach((icon) => this.matIconRegistry.addSvgIcon(
      icon.name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
    ))
  }

  private getUrlWithoutParams(url: string){
    let urlTree = this.router.parseUrl(url)
    urlTree.queryParams = {}
    urlTree.fragment = null

    return urlTree.toString()
  }

  readonly APP_VERSION = APP_VERSION
  readonly APP_VERSION_DATE = APP_VERSION_DATE

  private readonly componentDestroyedSubject = new Subject()
  readonly componentDestroyed$ = this.componentDestroyedSubject.asObservable()

  menuRoutes$: Observable<LocusRoute[]> = this.settingsService.mode$
    .pipe(
      map((mode) => {
        return ROUTES
          .filter((r) => r.ext.visibleInMenu && r.ext.availableForModes.indexOf(mode) >= 0)
      })
    )

  activatedUrl$ = this.router.events.pipe(
    filter((event: any) => event instanceof NavigationEnd),
    map((event: NavigationEnd) => this.getUrlWithoutParams(event.url)),
    shareReplay(1) // This is needed, subscription in view is after event fired from router
  )

  menuVisible$ = this.settingsService.menuVisible$

  activatedViewName?: string
  showViewLoadingIndicator = true

  @HostListener("window:message", ["$event"])
  receiveMessageEvent(event: MessageEvent) {
    console.log("Receiving event from", event.origin)
    if (environment.asamm.acceptedMessageOrigins.indexOf(event.origin) === -1) {
      throw new Error("Event not accepted because of the origin!")
    }

    // Should contain keys: kind, payload
    const eventData = event.data
    if (eventData.kind === undefined || eventData.payload === undefined) {
        console.log("Event missing 'kind' or 'payload' values -> ignoring")
      return
    }

    switch (eventData.kind) {
      case "lmIdToken":
        this.authService.updateToken(eventData.payload)
        this.authService.user$
          .pipe(take(1))
          .subscribe((currentUser) => {
            // Load user only once
            if (currentUser != undefined) {
              return
            }

            this.accountService
              .authenticate()
              .subscribe((user) => {
                this.authService.updateUser(user)
              })
          })
        break;
      default:
        throw new Error("Kind: " + eventData.kind + " is not supported!")
    }
  }

  ngAfterViewInit(): void {
    // Collapse menu by default on small screens
    if (this.breakpointObserver.isMatched('(max-width: 599px)')) {
      this.settingsService.setMenuVisible(false)
    }
  }

  ngOnInit(): void {
    // Not using Angular ActivatedRouteSnapshot because params are not resolved until
    // the route is fully resolved by router (e.g. waiting for activation of resolve)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    switch (urlParams.get(PARAM_MODE)) {
      case PARAM_MODE_ANDROID:
        console.log("Running in Android mode. Customizing UI ..")
        this.settingsService.setAppMode(AppMode.ANDROID)
        this.settingsService.setMenuVisible(false)

        break;
      case PARAM_MODE_IOS:
        console.log("Running in iOS mode. Customizing UI ..")
        this.settingsService.setAppMode(AppMode.IOS)
        this.settingsService.setMenuVisible(false)

        break;
    }

    this.activatedUrl$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((activatedUrl) => {
        const locusRoute = ROUTES.find((r) => ("/" + r.path) === activatedUrl)

        this.updateTitle(locusRoute)
        this.updateBreadcrumb(activatedUrl, locusRoute)
      })
  }

  ngOnDestroy(): void {
    this.componentDestroyedSubject.next(1)
  }

  private updateTitle(locusRoute?: LocusRoute) {
    let newTitle = LOCUS_ACCOUNT


    if (locusRoute) {
      newTitle += " | " + locusRoute.ext.name
    }

    this.title.setTitle(newTitle)
  }

  private updateBreadcrumb(activatedUrl: string, locusRoute?: LocusRoute) {
    if (activatedUrl == "") {
      this.activatedViewName = undefined
    } else {
      this.activatedViewName = (locusRoute !== undefined) ? locusRoute.ext.name : activatedUrl
    }
  }
}
