import {Route} from "@angular/router";

export interface AuthenticatedUser {
  userUid: string,
  email: string,
  name?: string, // TODO is it really optional?
  profilePictureUrl: string,
  allEmails: string[],
  gdprLocusMapConfirmedAt: number|null|undefined,
}

export interface UserProfile {
  name?: string,
  countryCode?: string,
  preferredLanguageCode?: string,
  yearOfBirth?: number,
  gender?: string,
  profilePictureUrl: string
}

export interface UserProfileUpdate {
  name: string,
  countryCode?: string,
  preferredLanguageCode?: string,
  yearOfBirth?: number,
  gender?: string
}

export interface RouteLocusExt {
  name: string,
  description: string,
  icon: string,
  visibleInMenu: boolean,
  availableForModes: AppMode[]
}

export interface LocusRoute extends Route {
  ext: RouteLocusExt
}

export interface ListOption {
  id: string,
  name: string
}

export enum AppMode {
  WEB = "WEB",
  ANDROID = "ANDROID",
  IOS = "IOS"
}

// Modal dialogs
export interface SnackBarData {
  message: string
}

// Generic
export interface AngularBuildEnvironment {
  production: boolean,
  asamm: {
    accountServiceUrl: string,
    locusStoreUrl: string,
    // Allowed origins which can send messages (mainly used to send id token from web planner to iframe [user profile])
    acceptedMessageOrigins: string[],
    accountServiceAuthToken: string
  },
  firebase: {
    apiKey: string,
    authDomain: string,
    databaseURL: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string,
    measurementId: string
  },
}

export namespace LocusStore {
  export const enum VoucherType {
    PREMIUM = "PREMIUM",
    ITEM = "ITEM",
    LOCOINS = "LOCOINS"
  }

  // Taken from Locus Map (ProductSource enum)
  export const enum  SubscriptionSource {
    UNDEFINED= "UNDEFINED",

    /**
     * Item is purchasable as "Subscription" item over store (Google Play, ...).
     */
    STORE_SUBSCRIPTION = "STORE_SUBSCRIPTION",

    /**
     * Item is purchasable as "In-app" purchase over store (Google Play, ...).
     */
    STORE_IN_APP = "STORE_IN_APP",

    /**
     * Item is purchasable over LoCoins.
     */
    LOCOIN_PURCHASE = "LOCOIN_PURCHASE",

    /**
     * Item is "purchasable" as part of voucher.
     */
    VOUCHER = "VOUCHER",

    APPLE_APP_STORE_SUBSCRIPTION = "APPLE_APP_STORE_SUBSCRIPTION"
  }

  export const enum SubscriptionPlan {
    LOCUS_MAP_SILVER = "LOCUS_MAP_SILVER",
    LOCUS_MAP_GOLD = "LOCUS_MAP_GOLD"
  }

  export const enum SubscriptionState {
    UNDEFINED = "UNDEFINED",
    ACTIVE = "ACTIVE",
    CANCELED = "CANCELED",
    ON_HOLD = "ON_HOLD",
    IN_GRACE_PERIOD = "IN_GRACE_PERIOD",
    PAUSED = "PAUSED",
    PAUSE_SCHEDULE_CHANGED = "PAUSE_SCHEDULE_CHANGED",
    REVOKED = "REVOKED",
    EXPIRED = "EXPIRED"
  }

  export interface ResponseStatus {
    responseStatus: {
      message: string,
      code: number
    }
  }

  export interface VoucherDetail {
    voucherCode: string,
    name: string,
    voucherType: VoucherType,
    loCoins: number,
    premiumItem: {
      productId: string,
      name: string,
      subscriptionSource: SubscriptionSource,
      subscriptionPlan: SubscriptionPlan,
      subscriptionPeriod: number,
      purchaseAble: boolean,
      hasTrial: boolean,

    }
  }

  export interface VoucherDetailResponse extends ResponseStatus {
    voucherDetail: VoucherDetail
  }

  export interface PrePurchaseRequest {
    productId: string
  }

  export interface PrePurchaseSubsParams {
    productId: string,
    externalSubsId: string,
    startMillis: number,
    expiryMillisOriginal: number,
    expiryMillisTrans: number,
    subscriptionStateTrans: string,
    refundedLoCoinMicros: number
  }

  export interface PrePurchaseResultParams {
    payload: string,
    computeTransitionInGooglePlay: boolean,
    currentSubsParams: PrePurchaseSubsParams[],
    nextSubsParams: PrePurchaseSubsParams
  }

  export interface PrePurchaseResponse extends ResponseStatus {
    prePurchaseResultParams: PrePurchaseResultParams
  }

  export interface PremiumVoucherRedeemRequest {
    payload: string,
    voucherCode: string,
    packageName: string
  }

  export interface UserPremiumSubscriptions {
    dsKey: string,
    productId: string,
    appType: number,
    autoRenewing: false,
    startTimeMillis: number,
    expiryTimeMillis: number,
    autoResumeTimeMillis: number,
    subscriptionState: SubscriptionState,
    subscriptionPlan: SubscriptionPlan,
    subscriptionSource: SubscriptionSource
  }

  export interface PremiumVoucherRedeemResponse extends ResponseStatus {
    userPremiumSubscriptions: UserPremiumSubscriptions
  }

  export interface VoucherConsumeDetail {
    voucherCode: string,
    consumeTime: number
  }

  export interface VoucherConsumeResponse extends ResponseStatus {
    voucherConsumeDetail: VoucherConsumeDetail
  }

  export interface PremiumItem {
    productId: string,
    name: string,
    subscriptionSource: SubscriptionSource,
    subscriptionPlan: SubscriptionPlan,
    subscriptionPeriod: number,
    purchaseAble: boolean,
    hasTrial: boolean
  }

  export interface PremiumItemsResponse extends ResponseStatus {
    items: PremiumItem[]
  }

  export interface AvailableItem {
    dsKey:	string,
    itemId:	number,
    itemVersionId: number
  }

  export interface UserProfile {
    asammUUID:	string,
    loCoins:	number,
    remainingWelcomePresents:	number,
    encryptKey:	string,
    availableItems:	AvailableItem[],
    premiumSubscriptions: UserPremiumSubscriptions[]
  }

  export interface UserProfileResponse extends ResponseStatus {
    userProfile: UserProfile
  }
}

export interface AssamRestErrorResponse {
  errorCode: string,
  errors: RestErrorItem[],
  incidentId: string,
  message: string,
  time: string
}

export interface RestErrorItem {
  message: string,
  type: string
}
