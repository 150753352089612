import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {LocusStoreService} from "../../shared/services/locus-store.service";
import {LocusStore} from "../../shared/dm";
import {MatStep} from "@angular/material/stepper";
import {MSG_VOUCHER_REDEMPTION_FAILURE} from "../redeem-voucher.component";
import {MatButton} from "@angular/material/button";
import VoucherDetail = LocusStore.VoucherDetail;

@Component({
  selector: 'redeem-voucher-locoin',
  templateUrl: './redeem-voucher-locoin.component.html',
  styleUrls: ['./redeem-voucher-locoin.component.scss']
})
export class RedeemVoucherLocoinComponent {

  constructor(
    private readonly locusStoreService: LocusStoreService
  ) {}

  @Input() voucherDetail!: VoucherDetail
  @Output() startNewVoucherRedemption = new EventEmitter<void>()

  @ViewChild("stepRedemptionResult") stepRedemptionResult!: MatStep

  loading = false
  redeemptionResultSuccessMsg?: string
  redeemptionResultFailureMsg?: string

  redeemVoucher() {
    this.loading = true
    this.stepRedemptionResult.select()
    // Focus button instead of stepper (looks weird in the UI)
    setTimeout(() => this.removeFocus())

    this.locusStoreService.applyLocoinVoucher(this.voucherDetail.voucherCode)
      .subscribe({
        next: (response) => {
          this.loading = false

          const numberOfLoCoins = this.voucherDetail.loCoins
          this.redeemptionResultSuccessMsg = $localize`:@@section.redeemVoucher.loCoin.msg.success:Done! You've successfully redeemed the LoCoin voucher. ${numberOfLoCoins} LoCoins have been added to your account.`;
        },
        error: () => {
          this.loading = false
          this.redeemptionResultFailureMsg = MSG_VOUCHER_REDEMPTION_FAILURE
        }
      });
  }

  applyNextVoucher() {
    this.startNewVoucherRedemption.emit()
  }

  private removeFocus() {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }
}
