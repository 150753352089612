<h2 i18n="@@section.personalInfo">Personal info</h2>

<mat-progress-bar *ngIf="loading else form"
                  mode="indeterminate">
</mat-progress-bar>

<ng-template #form>
  <form [formGroup]="formControl">
    <mat-form-field>
      <mat-label i18n="@@common.name">Name</mat-label>
      <input matInput
             type="text"
             [formControlName]="AccountForm.FIELD_NAME">
      <mat-icon matSuffix
                i18n-matTooltip="@@section.personalInfo.nameInfo"
                matTooltip="Other people can see your name when you share some content - photos, tracks etc.">info</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label i18n="@@common.gender">Gender</mat-label>
      <mat-select
        [formControlName]="AccountForm.FIELD_GENDER">
        <mat-option>---</mat-option>
        <mat-option [value]="'FEMALE'" i18n="@@common.gender.female">Female</mat-option>
        <mat-option [value]="'MALE'" i18n="@@common.gender.male">Male</mat-option>
        <mat-option [value]="'X'" i18n="@@common.gender.x">X</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label i18n="@@common.yearOfBirth">Year of birth</mat-label>
      <input matInput
             type="number"
             [formControlName]="AccountForm.FIELD_YEAR_OF_BIRTH">
    </mat-form-field>

    <mat-form-field>
      <mat-label i18n="@@common.country">Country</mat-label>
      <mat-select
        [formControlName]="AccountForm.FIELD_COUNTRY_CODE">
        <mat-option>---</mat-option>
        <mat-option *ngFor="let country of countries"
                    [value]="country.id">
          {{ country.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label i18n="@@common.language">Language</mat-label>
      <mat-select
        [formControlName]="AccountForm.FIELD_PREFERRED_LANGUAGE_CODE">
        <mat-option>---</mat-option>
        <mat-option *ngFor="let language of languages"
                    [value]="language.id">
          {{ language.name }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix
                i18n-matTooltip="@@section.personalInfo.languageInfo"
                matTooltip="Choose the language to communicate with you.">info</mat-icon>
    </mat-form-field>
  </form>

  <div class="form-actions">
    <button mat-flat-button
            color="primary"
            (click)="save()"
            [disabled]="saving || formControl.invalid">
      <ng-container *ngIf="!saving" i18n="@@common.action.save">Save</ng-container>
      <ng-container *ngIf="saving" i18n="@@common.action.saving">Saving...</ng-container>
    </button>
  </div>

</ng-template>
