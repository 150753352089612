import {Component} from "@angular/core";
import {AuthService} from "../shared/services/auth.service";
import {combineLatest, of, switchMap, take} from "rxjs";
import {NotificationService} from "../shared/services/notification.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LocusStoreService} from "../shared/services/locus-store.service";
import {LocusStore} from "../shared/dm";
import VoucherDetail = LocusStore.VoucherDetail;
import PrePurchaseResultParams = LocusStore.PrePurchaseResultParams;
import PremiumItem = LocusStore.PremiumItem;
import {user} from "@angular/fire/auth";

export namespace ApplyVoucherForm {
  export const FIELD_VOUCHER = "voucher"
}

export const MSG_VOUCHER_REDEMPTION_SUCCESSFUL = $localize`:@@section.redeemVoucher.msg.success:Done! You've successfully redeemed the voucher.`
export const MSG_VOUCHER_REDEMPTION_FAILURE = $localize`:@@section.redeemVoucher.msg.failure:Sorry but we couldn't redeem the voucher.`
const MSG_VOCUHER_APPLIED_FAILURE_VOUCHER_NOT_SUPPORTED
  = $localize`:@@section.redeemVoucher.msg.failure.voucherNotSupportedViaWeb:"Sorry but this voucher can not be redeemed here."`

enum RedeemStep {
  VOUCHER_INPUT = 'VOUCHER_INPUT',
  LOCOIN_VOUCHER = 'LOCOIN_VOUCHER',
  PREMIUM_VOUCHER = 'PREMIUM_VOUCHER',
}

@Component({
  selector: "redeem-voucher",
  templateUrl: "redeem-voucher.component.html",
  styleUrls: ["redeem-voucher.component.scss"]
})
export class RedeemVoucherComponent {
  constructor(
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    private readonly locusStoreService: LocusStoreService
  ) {
  }

  loading = false
  redeemStep: RedeemStep = RedeemStep.VOUCHER_INPUT
  readonly RedeemStep = RedeemStep
  readonly ApplyVoucherForm = ApplyVoucherForm

  voucherDetail?: VoucherDetail

  // Premium
  prePurchaseResult?: PrePurchaseResultParams
  userProfile?: LocusStore.UserProfile
  premiumItems: PremiumItem[] = []

  formControl = new FormGroup({
    [ApplyVoucherForm.FIELD_VOUCHER]: new FormControl(null, Validators.required),
  })

  redeem() {
    this.loading = true

    this.authService.authToken$
      .pipe(take(1))
      .subscribe((authToken) => {
        if (!authToken)
          return this.loadingVoucherDetailsFailed()

        this.locusStoreService.loadVoucherDetail(this.getVoucherValue()).subscribe({
          next: (voucherDetail) => {
            this.loading = false
            this.voucherDetail = voucherDetail
            this.handleVoucherDetail(voucherDetail)
          },
          error: () => {
            this.loadingVoucherDetailsFailed()
          }
        });
      })
  }

  private handleVoucherDetail(voucherDetail: VoucherDetail) {
    switch (voucherDetail.voucherType) {
      case LocusStore.VoucherType.PREMIUM:
        this.prepurchasePremiumVoucher(voucherDetail.premiumItem.productId)
        break
      case LocusStore.VoucherType.LOCOINS:
        this.redeemStep = RedeemStep.LOCOIN_VOUCHER
        break
      default:
        this.handleUnsupportedVoucherType()
    }
  }

  private prepurchasePremiumVoucher(productId: string) {
    this.loading = true

    const prepurchaseResult = this.locusStoreService.prepurchaseVoucher(productId)
      .pipe(
        switchMap((prepurchaseResult) => {
          const subscriptionIds = [
            prepurchaseResult.nextSubsParams.productId,
            ...prepurchaseResult.currentSubsParams.map((p) => p.productId)
          ]

          return this.locusStoreService.loadPremiumItems(subscriptionIds).pipe(
            switchMap((premiumItems) => of({
              prepurchaseResult,
              premiumItems
            }))
          )
        })
      )

    combineLatest([
      prepurchaseResult,
      this.locusStoreService.loadUserProfile()
    ])
      .subscribe({
        next: ([prepurchaseResponse, userProfile]) => {
          this.loading = false
          this.redeemStep = RedeemStep.PREMIUM_VOUCHER

          this.prePurchaseResult = prepurchaseResponse.prepurchaseResult
          this.premiumItems = prepurchaseResponse.premiumItems
          this.userProfile = userProfile
        },
        error: () => this.loadingVoucherDetailsFailed()
      });
  }


  private handleUnsupportedVoucherType() {
    this.notificationService.showErrorMessage(MSG_VOCUHER_APPLIED_FAILURE_VOUCHER_NOT_SUPPORTED)
    this.loadingVoucherDetailsFailed()
  }

  private getVoucherValue(): string {
    return this.formControl.value[ApplyVoucherForm.FIELD_VOUCHER]
  }

  private loadingVoucherDetailsFailed() {
    // Error already shown in the service

    this.loading = false
    this.redeemStep = RedeemStep.VOUCHER_INPUT
  }

  reset() {
    this.loading = false
    this.redeemStep = RedeemStep.VOUCHER_INPUT

    // Reset state variables
    this.voucherDetail = undefined
    this.prePurchaseResult = undefined
    this.userProfile = undefined
    this.premiumItems = []

    // Reset form
    this.formControl.reset()
  }

  protected readonly user = user;
}
