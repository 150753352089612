<h2 i18n="@@section.redeemVoucher.locoin.heading">Redeem LoCoin voucher</h2>

<mat-stepper linear>
  <mat-step editable="false">
    <ng-template matStepLabel>
      <ng-container i18n="@@common.info">Info</ng-container>
    </ng-template>

    <table *ngIf="voucherDetail">
      <tr>
        <th i18n="@@common.voucher">Voucher</th>
        <td>{{ voucherDetail.voucherCode }}</td>
      </tr>
      <tr>
        <th i18n="@@common.title">Title</th>
        <td>{{ voucherDetail.name }}</td>
      </tr>
      <tr>
        <th i18n="@@common.amount">Amount (LoCoins)</th>
        <td>{{ voucherDetail.loCoins }}</td>
      </tr>
    </table>

    <div class="form-actions">
      <button mat-flat-button
              color="primary"
              (click)="redeemVoucher()"
              i18n="@@common.action.redeem">Redeem</button>

      <button mat-flat-button
              color="accent"
              (click)="applyNextVoucher()"
              i18n="@@common.action.cancel">Cancel</button>
    </div>
  </mat-step>

  <mat-step editable="false" #stepRedemptionResult>
    <ng-template matStepLabel>
      <ng-container i18n="@@common.confirmation">Confirmation</ng-container>
    </ng-template>

    <mat-progress-bar *ngIf="loading else redemptionResultMsg"
                      mode="indeterminate">
    </mat-progress-bar>

    <ng-template #redemptionResultMsg>
        <div class="alert alert-success" *ngIf="redeemptionResultSuccessMsg">{{ redeemptionResultSuccessMsg }}</div>

        <div class="alert alert-error" *ngIf="redeemptionResultFailureMsg">{{ redeemptionResultFailureMsg }}</div>

      <div class="form-actions">
        <button mat-flat-button
                color="primary"
                i18n="@@common.action.close"
                (click)="applyNextVoucher()">Close</button>
      </div>
    </ng-template>
  </mat-step>
</mat-stepper>
