import {TransitionMode} from "../../subscription/subscription-utils";

interface TransitionMsg {
  transitionMode: TransitionMode,
  translatedMsg: string
}

// See ConfirmPurchaseDialog.kt->getExtraInfo() for details
export const TRANSITION_MESSAGES: TransitionMsg[] = [
  {
    transitionMode: TransitionMode.GOLD_OT_SILVER_OT,
    translatedMsg: $localize`:@@redeemVoucher.premium.transitionMsg.GOLD_OT_SILVER_OT:Fair change! We will use your remaining funds for the original Gold to extend the new one-time Silver purchase accordingly.`
  },
  {
    transitionMode: TransitionMode.SILVER_OT_GOLD_OT,
    translatedMsg: $localize`:@@redeemVoucher.premium.transitionMsg.SILVER_OT_GOLD_OT:Fair upgrade! The app will be switched to Gold immediately. The funds for the remaining Silver will be used to extend the new one-time Gold period accordingly.`
  },
  {
    transitionMode: TransitionMode.SAME_TIER_OT_OT,
    translatedMsg: $localize`:@@redeemVoucher.premium.transitionMsg.SAME_TIER_OT_OT:Fair prolongation! We’ll add the unused time of the original period to your new one-time purchase.`
  },
  {
    transitionMode: TransitionMode.SILVER_SUBS_GOLD_OT,
    translatedMsg: $localize`:@@redeemVoucher.premium.transitionMsg.SILVER_SUBS_GOLD_OT:Fair upgrade! We'll suspend your Silver subscription while you enjoy the one-time Gold. After the end of the one-time payment period, the subscription term will continue until its full length expires.`
  },
  {
    transitionMode: TransitionMode.SILVER_OT_GOLD_SUBS,
    translatedMsg: $localize`:@@redeemVoucher.premium.transitionMsg.SILVER_OT_GOLD_SUBS:Fair upgrade! The app will be switched to Gold immediately. The funds for the remaining Silver will be used to extend the new Gold subscription term accordingly.`
  },
  {
    transitionMode: TransitionMode.SAME_TIER_OT_SUBS,
    translatedMsg: $localize`:@@redeemVoucher.premium.transitionMsg.SAME_TIER_OT_SUBS:Fair change! We’ll use the remaining funds for the original one-time period to extend the new subscription term accordingly.`
  },
  {
    transitionMode: TransitionMode.GOLD_OT_SILVER_SUBS,
    translatedMsg: $localize`:@@redeemVoucher.premium.transitionMsg.GOLD_OT_SILVER_SUBS:Fair change! We’ll use your remaining funds for the original Gold to extend the new Silver subscription term accordingly.`
  },
]
