<div class="links">
  <div *ngFor="let route of menuRoutes$ | async"
     [routerLink]="route.path"
     class="link">
    <div class="title">
      <mat-icon title="{{ route.ext.name }}">{{ route.ext.icon }}</mat-icon>
      {{ route.ext.name }}
    </div>

    <div class="description">
      {{ route.ext.description }}
    </div>
  </div>
</div>
