import {Component, Inject} from "@angular/core";
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {SnackBarData} from "../../dm";

@Component({
  selector: "snackbar",
  templateUrl: "snackbar.component.html",
  styleUrls: ["snackbar.component.scss"]
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) readonly data: SnackBarData,
    private snackBarRef: MatSnackBarRef<SnackbarComponent>,
  ) { }

  dismiss() {
    this.snackBarRef.dismiss()
  }
}
