<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<ng-container [ngSwitch]="redeemStep">
  <ng-container *ngSwitchCase="RedeemStep.VOUCHER_INPUT">
    <h2 i18n="@@section.redeemVoucher">Redeem voucher</h2>

    <form [formGroup]="formControl">
      <mat-form-field>
        <mat-label i18n="@@common.voucherCode">Voucher code</mat-label>
        <input matInput
               type="text"
               [formControlName]="ApplyVoucherForm.FIELD_VOUCHER">
      </mat-form-field>
    </form>

    <div class="form-actions">
      <button mat-flat-button
              color="primary"
              (click)="redeem()"
              [disabled]="loading || formControl.invalid">
        <ng-container *ngIf="!loading" i18n="@@common.action.loadVoucherDetails">Load voucher details</ng-container>
        <ng-container *ngIf="loading" i18n="@@common.action.working">Working...</ng-container>
      </button>
    </div>
  </ng-container>


  <ng-container *ngSwitchCase="RedeemStep.LOCOIN_VOUCHER">
    <redeem-voucher-locoin *ngIf="voucherDetail"
                           [voucherDetail]="voucherDetail"
                           (startNewVoucherRedemption)="reset()">
    </redeem-voucher-locoin>
  </ng-container>

  <ng-container *ngSwitchCase="RedeemStep.PREMIUM_VOUCHER">
    <redeem-voucher-premium *ngIf="voucherDetail && prePurchaseResult && premiumItems && userProfile"
                            [voucherDetail]="voucherDetail"
                            [prePurchaseResult]="prePurchaseResult"
                            [premiumItems]="premiumItems"
                            [userProfile]="userProfile"
                            (startNewVoucherRedemption)="reset()">
    </redeem-voucher-premium>
  </ng-container>
</ng-container>
