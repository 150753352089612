import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {SubscriptionComponent} from "./subscription/subscription.component";
import {NotificationService} from "./shared/services/notification.service";
import {SnackbarComponent} from "./shared/components/snackbar/snackbar.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {HttpClientModule} from "@angular/common/http";
import {AuthService} from "./shared/services/auth.service";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {CanActivateLoggedIn} from "./shared/activation/can-activate-logged-in";
import {PersonalInfoComponent} from "./personal-info/personal-info.component";
import {AccountService} from "./shared/services/account.service";
import {MatDividerModule} from "@angular/material/divider";
import {SettingsService} from "./shared/services/settings.service";
import {LanguagesResolver} from "./shared/resolvers/languages.resolver";
import {CountriesResolver} from "./shared/resolvers/countries.resolver";
import {MatTabsModule} from "@angular/material/tabs";
import {HomeComponent} from "./home/home.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ProfilePictureComponent} from "./profile-picture/profile-picture.component";
import {PasswordChangeComponent} from "./password-change/password-change.component";
import {environment} from "../environments/environment";
import {AngularFireModule} from "@angular/fire/compat";
import {RedeemVoucherComponent} from "./redeem-voucher/redeem-voucher.component";
import {LocusStoreService} from "./shared/services/locus-store.service";
import {RedeemVoucherLocoinComponent} from "./redeem-voucher/redeem-voucher-locoin/redeem-voucher-locoin.component";
import {MatStepperModule} from "@angular/material/stepper";
import {RedeemVoucherPremiumComponent} from "./redeem-voucher/redeam-voucher-premium/redeem-voucher-premium.component";
import {AccountDeletionComponent} from "./account-deletion/account-deletion.component";
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
  declarations: [
    AppComponent,
    PersonalInfoComponent,
    SubscriptionComponent,
    SnackbarComponent,
    ProfilePictureComponent,
    HomeComponent,
    PasswordChangeComponent,
    RedeemVoucherComponent,
    RedeemVoucherLocoinComponent,
    RedeemVoucherPremiumComponent,
    AccountDeletionComponent
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatDividerModule,
        MatTabsModule,
        MatTooltipModule,
        AngularFireModule.initializeApp(environment.firebase),
        MatStepperModule,
        MatCheckboxModule,
    ],
  providers: [
    AccountService,
    AuthService,
    NotificationService,
    CanActivateLoggedIn,
    SettingsService,
    Title,
    LanguagesResolver,
    CountriesResolver,
    LocusStoreService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
