import {Component} from "@angular/core";
import {SettingsService} from "../shared/services/settings.service";
import {map, Observable} from "rxjs";
import {ROUTES} from "../app-routing.module";
import {LocusRoute} from "../shared/dm";

@Component({
  selector: "home",
  templateUrl: "home.component.html",
  styleUrls: ["home.component.scss"]
})
export class HomeComponent {

  constructor(
    private readonly settingsService: SettingsService
  ) { }

  readonly menuRoutes$: Observable<LocusRoute[]> = this.settingsService.mode$
    .pipe(
      map((mode) => {
        return ROUTES
          .filter((r) => r.ext.visibleInMenu && r.ext.availableForModes.indexOf(mode) >= 0)
      })
    )
}
