import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CanActivateLoggedIn} from "./shared/activation/can-activate-logged-in";
import {PersonalInfoComponent} from "./personal-info/personal-info.component";
import {LanguagesResolver} from "./shared/resolvers/languages.resolver";
import {CountriesResolver} from "./shared/resolvers/countries.resolver";
import {HomeComponent} from "./home/home.component";
import {AppMode, LocusRoute} from "./shared/dm";
import {ProfilePictureComponent} from "./profile-picture/profile-picture.component";
import {PasswordChangeComponent} from "./password-change/password-change.component";
import {RedeemVoucherComponent} from "./redeem-voucher/redeem-voucher.component";
import {AccountDeletionComponent} from "./account-deletion/account-deletion.component";


export const ROUTES: LocusRoute[] = [
  {
    component: HomeComponent,
    path: "",
    ext: {
      name: "",
      description: "",
      icon: "",
      visibleInMenu: false,
      availableForModes: [
        AppMode.ANDROID,
        AppMode.IOS,
        AppMode.WEB
      ]
    }
  },
  {
    component: PersonalInfoComponent,
    path: "personal-info",
    canActivate: [CanActivateLoggedIn],
    resolve: {
      countries: CountriesResolver,
      languages: LanguagesResolver
    },
    ext: {
      name: $localize `:@@section.personalInfo:Personal info`,
      description: $localize `:@@section.personalInfoDescription:Edit your name, select country, language and more`,
      icon: "badge",
      visibleInMenu: true,
      availableForModes: [
        AppMode.ANDROID,
        AppMode.IOS,
        AppMode.WEB
      ]
    }
  },
  {
    component: ProfilePictureComponent,
    path: "profile-picture",
    canActivate: [CanActivateLoggedIn],
    ext: {
      name: $localize `:@@common.profilePicture:Profile picture`,
      description: $localize `:@@common.profilePictureDescription:Add or remove your profile picture`,
      icon: "portrait",
      visibleInMenu: true,
      availableForModes: [
        AppMode.ANDROID,
        AppMode.IOS,
        AppMode.WEB
      ]
    }
  },
  {
    component: PasswordChangeComponent,
    path: "change-password",
    canActivate: [CanActivateLoggedIn],
    ext: {
      name: $localize `:@@section.changePassword:Change password`,
      description: $localize `:@@section.changePasswordDescription:Just in case you use email/password login`,
      icon: "password",
      visibleInMenu: true,
      availableForModes: [
        AppMode.ANDROID,
        AppMode.IOS,
        AppMode.WEB
      ]
    }
  },
  {
    component: RedeemVoucherComponent,
    path: "redeem-voucher",
    canActivate: [CanActivateLoggedIn],
    ext: {
      name: $localize `:@@section.redeemVoucher:Redeem voucher`,
      description: $localize `:@@section.redeemVoucherDescription:You can redeem your voucher here`,
      icon: "subtitles",
      visibleInMenu: true,
      availableForModes: [
        AppMode.WEB
      ]
    }
  },
  {
    component: AccountDeletionComponent,
    path: "delete-account",
    canActivate: [CanActivateLoggedIn],
    ext: {
      name: $localize `:@@section.accountDeletion:Delete account`,
      description: $localize `:@@section.accountDeletion.description:Send a request to delete your account and all associated data`,
      icon: "person_off",
      visibleInMenu: true,
      availableForModes: [
        AppMode.ANDROID,
        AppMode.IOS,
        AppMode.WEB
      ]
    }
  }
  // {
  //   component: SubscriptionComponent,
  //   path: "subscription",
  //   canActivate: [CanActivateLoggedIn],
  //   ext: {
  //     name: $localize `:@@common.subscription:Subscription`,
  //     icon: "workspace_premium",
  //     availableForModes: [AppMode.ANDROID, AppMode.WEB]
  //   }
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
