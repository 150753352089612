import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {filter, map, mapTo, Observable} from "rxjs";
import {AuthService} from "../services/auth.service";

@Injectable()
export class CanActivateLoggedIn implements CanActivate {

  constructor(
    private readonly authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.authToken$.pipe(
      filter((token) => token !== undefined),
      map(() => true)
    );
  }
}
