import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ListOption, UserProfileUpdate} from "../shared/dm";
import {NotificationService} from "../shared/services/notification.service";
import {AccountService} from "../shared/services/account.service";
import {ActivatedRoute} from "@angular/router";

export namespace AccountForm {
  export const FIELD_NAME = "name"
  export const FIELD_COUNTRY_CODE = "countryCode"
  export const FIELD_PREFERRED_LANGUAGE_CODE = "preferredLanguageCode"
  export const FIELD_YEAR_OF_BIRTH = "yearOfBirth"
  export const FIELD_GENDER = "gender"
}

const MSG_PROFILE_SAVED = $localize `:@@rest.profileSaved:You've successfully saved your profile`


@Component({
  selector: "personal-info",
  templateUrl: "personal-info.component.html",
  styleUrls: ["personal-info.component.scss"]
})
export class PersonalInfoComponent implements OnInit {
  constructor(
    private readonly accountService: AccountService,
    private readonly notificationService: NotificationService,
    private readonly route: ActivatedRoute
  ) { }

  loading = false
  saving = false

  AccountForm = AccountForm

  formControl = new FormGroup({
    [AccountForm.FIELD_NAME]: new FormControl(null, Validators.required),
    [AccountForm.FIELD_GENDER]: new FormControl(null),
    [AccountForm.FIELD_YEAR_OF_BIRTH]: new FormControl(null, [
      Validators.min(1900),
      Validators.max(new Date().getFullYear())
    ]),
    [AccountForm.FIELD_COUNTRY_CODE]: new FormControl(null),
    [AccountForm.FIELD_PREFERRED_LANGUAGE_CODE]: new FormControl(null),
  })

  countries: ListOption[] = []
  languages: ListOption[] = []

  save() {
    this.saving = true
    const updatedUserProfile: UserProfileUpdate = {
      name: this.formControl.value[AccountForm.FIELD_NAME],
      gender: this.formControl.value[AccountForm.FIELD_GENDER],
      yearOfBirth: this.formControl.value[AccountForm.FIELD_YEAR_OF_BIRTH],
      countryCode: this.formControl.value[AccountForm.FIELD_COUNTRY_CODE],
      preferredLanguageCode: this.formControl.value[AccountForm.FIELD_PREFERRED_LANGUAGE_CODE]
    }

    this.accountService.updateUserProfile(updatedUserProfile).subscribe({
      next: () => {
        this.notificationService.showSuccessMessage(MSG_PROFILE_SAVED, 3000)
        this.saving = false
      },
      error: () => this.saving = false
    })
  }

  ngOnInit(): void {
    this.countries = this.route.snapshot.data["countries"]
    this.languages = this.route.snapshot.data["languages"]

    this.loading = true
    this.accountService.loadUserProfile().subscribe({
      next: (userProfile) => {
        this.formControl.patchValue({
          [AccountForm.FIELD_NAME]: userProfile.name,
          [AccountForm.FIELD_GENDER]: userProfile.gender,
          [AccountForm.FIELD_YEAR_OF_BIRTH]: userProfile.yearOfBirth,
          [AccountForm.FIELD_COUNTRY_CODE]: userProfile.countryCode,
          [AccountForm.FIELD_PREFERRED_LANGUAGE_CODE]: userProfile.preferredLanguageCode
        })

        this.loading = false
      },
      error: () => this.loading = false
    })
  }
}
