<h2 i18n="@@section.accountDeletion.title">Account deletion</h2>

<mat-stepper linear>
  <mat-step [editable]="false"
            [completed]="false"
            #stepRequest
  >
    <ng-template matStepLabel>
      <ng-container i18n="@@common.request">Request</ng-container>
    </ng-template>

    <div class="alert alert-warning">
      <p i18n="Warnign text displayed at the start of account deletion process. First paragraph - online data deleting time@@section.accountDeletion.step.initiateRequest.p1">
        If you request deletion of your account, it will be suspended within 7 days and all associated data, including shared routes, will be deleted from our servers within 30 days. <strong>This action is irreversible.</strong>
      </p>
      <p i18n="Warnign text displayed at the start of account deletion process. Second paragraph - offline data need to be deleted by user.@@section.accountDeletion.step.initiateRequest.p2">
        Some MyLibrary data stored on your mobile device will not be affected because it doesn't require an account connection. You can delete it by uninstalling Locus Map or deleting its working directory.
      </p>
    </div>

    <p i18n="Start of account deletion process. Ask user for optional provided reason why leaving.@@section.accountDeletion.step.initiateRequest.msg.provideOptionalReason">
      We regret your decision and respect it. However, we would appreciate it if you could optionally provide a reason for your request.
    </p>

      <form [formGroup]="requestFormControl">
        <mat-form-field>
          <mat-label i18n="@@common.reason">Reason</mat-label>
          <textarea matInput
                    type="text"
                    rows="3"
                    [formControlName]="AccountDeletionForm.FIELD_REASON">
          </textarea>
        </mat-form-field>
      </form>

    <div class="form-actions">
      <button mat-flat-button
              color="primary"
              (click)="goToConfirmation()"
              i18n="@@common.action.continue">Continue</button>
    </div>
  </mat-step>

  <mat-step [editable]="false"
            [completed]="false"
            [stepControl]="confirmationFormControl"
            #stepRequestConfirmation
  >
    <ng-template matStepLabel>
      <ng-container i18n="@@common.confirmation">Confirmation</ng-container>
    </ng-template>

    <mat-progress-bar *ngIf="sendingRequest else confirmationForm"
                      mode="indeterminate">
    </mat-progress-bar>

    <ng-template #confirmationForm>
      <form [formGroup]="confirmationFormControl">
        <mat-checkbox [formControlName]="AccountDeletionConfirmationForm.FIELD_IRREVERSIBLE_OPERATION_CONFIRMATION">
          <!-- Use white-space: normal for long text break to more lines if needed -->
          <span i18n="Confirmation step of user deletion. Label of checkbox that has to be checked in order to sent account deletion request.@@accountDeletion.step.requestConfirmation.userAwareDeleteIsIrrebersible"
                style="white-space: normal">I am aware that this operation is irreversible and I request the deletion of my user account and associated personal data.</span>
        </mat-checkbox>
      </form>

      <div class="form-actions">
        <button mat-flat-button
                color="warn"
                [disabled]="confirmationFormControl.invalid"
                (click)="deleteAccountPermanently()"
                i18n="Button that triggers sending account deletion request to the server.@@accountDeletion.step.requestConfirmation.action.deleteAccountPermanently">Delete account permanently</button>
      </div>
    </ng-template>
  </mat-step>

  <mat-step #stepRequestResult>
    <ng-template matStepLabel>
      <ng-container i18n="@@common.result">Result</ng-container>
    </ng-template>

    <div class="alert alert-success" *ngIf="!requestFailureMsg else requestFailed">
      <p i18n="Text shown to user after they have successfully sent an account removal request. Informs the user that data will be removed within a specific period. Displays as part one of a multi-part message.@@accountDeletion.step.result.requestSubmittedMsg.p1">
        Your request has been submitted. The account will be suspended within 7 days and the associated data will be deleted within 30 days.
      </p>

      <p i18n="Second part of the message shown to the user after a successful account removal request. Informs the user that local data in the app has not been deleted and requires user's manual action.@@accountDeletion.step.result.requestSubmittedMsg.p2">
        To delete data stored locally in the mobile application, please uninstall it or delete its working directory.
      </p>
    </div>

    <ng-template #requestFailed>
      <div class="alert alert-error">
        {{ requestFailureMsg }}
      </div>
    </ng-template>

  </mat-step>
</mat-stepper>
