<h2 i18n="@@section.changePassword">Change password</h2>

<mat-progress-bar *ngIf="loading else content"
                  mode="indeterminate">
</mat-progress-bar>

<ng-template #content>
  <div *ngIf="hasEmailPasswordAuthProvider else showProviderWarning">
    <form [formGroup]="formControl">
      <mat-form-field>
        <mat-label i18n="@@common.currentPassword">Current password</mat-label>
        <input matInput
               type="password"
               [formControlName]="PasswordChangeForm.FIELD_CURRENT_PASSWORD">
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@common.newPassword">New password</mat-label>
        <input matInput
               type="password"
               [formControlName]="PasswordChangeForm.FIELD_NEW_PASSWORD">
        <mat-error>
          <ng-container *ngIf="newPasswordControl.hasError('minlength')"
                        i18n="common.validation.minLength6">Use at least 6 characters.
          </ng-container>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@common.newPasswordConfirmation">New password (just in case)</mat-label>
        <input matInput
               type="password"
               [formControlName]="PasswordChangeForm.FIELD_NEW_PASSWORD_CONFIRMATION">
      </mat-form-field>
    </form>

    <div *ngIf="formControl.hasError(PasswordChangeForm.VALIDATION_PASSWORDS_MATCHING) && (formControl.touched || formControl.dirty)"
         class="passwords-not-matching-err-msg"
         i18n="@@section.changePassword.passwordsAreDifferent">The new password and its confirmation must match.
    </div>

    <div class="form-actions">
      <button mat-flat-button
              color="primary"
              (click)="changePassword()"
              [disabled]="saving || formControl.invalid">
        <ng-container *ngIf="!saving" i18n="@@common.action.change">Change</ng-container>
        <ng-container *ngIf="saving" i18n="@@common.action.changing">Changing...</ng-container>
      </button>
    </div>
  </div>

  <ng-template #showProviderWarning>
    <div class="alert alert-warning">
      <mat-icon>warning</mat-icon>
      <ng-container i18n="@@section.changePassword.msg.onlyAvailableForEmailPassword">Available only when you use email/password login.</ng-container>
    </div>
  </ng-template>
</ng-template>
