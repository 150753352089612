<div class="container">
  <aside *ngIf="(menuVisible$ | async) && activatedViewName">
    <div class="links">
      <a *ngFor="let route of menuRoutes$ | async"
         [routerLink]="route.path"
         class="link"
         [class.activated]="(activatedUrl$ | async) == ('/' + route.path)">
        <mat-icon title="{{ route.ext.name }}">{{ route.ext.icon }}</mat-icon>

        <span class="link-name">{{ route.ext.name }}</span>
      </a>
    </div>
  </aside>

  <main>
    <nav class="breadcrumb-navigation"
         *ngIf="activatedViewName">
      <a [routerLink]="'/'">
        <mat-icon>manage_accounts</mat-icon>
        <ng-container i18n="@@common.editAccount">Edit account</ng-container>
      </a>

      <mat-icon class="separator">chevron_right</mat-icon>

      {{ activatedViewName }}
    </nav>

    <router-outlet (activate)="showViewLoadingIndicator=false"
                   (deactivate)="showViewLoadingIndicator=true">
      <div>
        <mat-progress-bar *ngIf="showViewLoadingIndicator"
                          mode="query">
        </mat-progress-bar>
      </div>
    </router-outlet>
  </main>
</div>

<footer>
  <div class="service-info">
    <ng-container i18n="@@common.locusAccount">Locus account</ng-container>
    | Asamm Software, s. r. o.
    | v{{ APP_VERSION }} ({{ APP_VERSION_DATE }})
  </div>
</footer>
