import {AngularBuildEnvironment} from "../app/shared/dm";

export const environment: AngularBuildEnvironment = {
  production: false,
  asamm: {
    accountServiceUrl: "https://account.dev.asamm.com",
    locusStoreUrl: "https://locus-store-dev.appspot.com",
    acceptedMessageOrigins: [
      "https://myaccount.dev.asamm.com", // APP Webview
      "https://web.dev.locusmap.app",
    ],
    accountServiceAuthToken: "AccountServiceUixx2XrML3vBbhWownBRWbmUNm4KUFD4DS7spXm5Os05WhFliJ"
  },
  firebase: {
    apiKey: "AIzaSyDiRMpGalzOcNcTIFXMN7lNC6LRmjuTaFo",
    authDomain: "locus-store-dev.firebaseapp.com",
    databaseURL: "https://locus-store-dev.firebaseio.com",
    projectId: "locus-store-dev",
    storageBucket: "locus-store-dev.appspot.com",
    messagingSenderId: "756204941327",
    appId: "1:756204941327:web:2be0b4bfad967464b4b37a",
    measurementId: "G-4RWD8WVVC9"
  },
};
