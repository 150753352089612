import {Component, OnInit} from "@angular/core";
import {AuthService} from "../shared/services/auth.service";
import {combineLatest, filter, take} from "rxjs";
import {NotificationService} from "../shared/services/notification.service";
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {AngularFireAuth} from "@angular/fire/compat/auth";

export namespace PasswordChangeForm {
  export const FIELD_CURRENT_PASSWORD = "currentPassword"
  export const FIELD_NEW_PASSWORD = "newPassword"
  export const FIELD_NEW_PASSWORD_CONFIRMATION = "newPasswordConfirmation"
  export const VALIDATION_PASSWORDS_MATCHING = "matchingPasswords"
}

const passwordsMatchingValidator: ValidatorFn = (control: AbstractControl) => {
  const newPasswordControl = control.get(PasswordChangeForm.FIELD_NEW_PASSWORD)
  const newPasswordControlConfirmation = control.get(PasswordChangeForm.FIELD_NEW_PASSWORD_CONFIRMATION)

  return newPasswordControl && newPasswordControlConfirmation
  && newPasswordControl.value === newPasswordControlConfirmation.value
    ? null
    : {[PasswordChangeForm.VALIDATION_PASSWORDS_MATCHING]: "Does not match new password."}
}

const MSG_PASSWORD_CHANGE_SUCCESSFUL = $localize `:@@section.changePassword.msg.success:Done! You've successfully changed your password.`
const MSG_PASSWORD_CHANGE_FAILURE = $localize `:@@section.changePassword.msg.failure:Sorry but the password change failed. Please try it again later.`
const MSG_WRONG_PASSWORD = $localize `:@@common.msg.wrongPassword:This is not the right password. Try another one.`
const MSG_WEAK_PASSWORD = $localize `:@@common.msg.weakPassword:Your new password is too weak and it could be easily breached. Use at least 6 characters.`

@Component({
  selector: "password-change",
  templateUrl: "password-change.component.html",
  styleUrls: ["password-change.component.scss"]
})
export class PasswordChangeComponent implements OnInit {
  constructor(
    private readonly angularFireAuth: AngularFireAuth,
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.authService.user$
      .pipe(
        filter((user) => user != undefined),
        take(1)
      )
      .subscribe((user) => {
        this.angularFireAuth.fetchSignInMethodsForEmail(user!!.email)
          .then((providers) => {
            if (providers.indexOf("password") == -1) {
              this.hasEmailPasswordAuthProvider = false
            }
            this.loading = false
          })
      })
  }

  PasswordChangeForm = PasswordChangeForm

  loading = true
  saving = false
  hasEmailPasswordAuthProvider = true

  newPasswordControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(6)
  ])

  formControl = new FormGroup({
      [PasswordChangeForm.FIELD_CURRENT_PASSWORD]: new FormControl(null, Validators.required),
      [PasswordChangeForm.FIELD_NEW_PASSWORD]: this.newPasswordControl,
      [PasswordChangeForm.FIELD_NEW_PASSWORD_CONFIRMATION]: new FormControl(null, [Validators.required])
    }, {validators: passwordsMatchingValidator}
  )

  changePassword() {
    const currentPassword = this.formControl.value[PasswordChangeForm.FIELD_CURRENT_PASSWORD]
    const newPassword = this.formControl.value[PasswordChangeForm.FIELD_NEW_PASSWORD]
    this.saving = true

    combineLatest([
      this.authService.authToken$,
      this.authService.user$
    ])
      .pipe(take(1))
      .subscribe(([authToken, user]) => {
        if (authToken == undefined || user == undefined) {
          this.saving = false
          return
        }

        this.angularFireAuth.signInWithEmailAndPassword(user.email, currentPassword)
          .then((credential) => {
            credential.user!!.updatePassword(newPassword)
              .then(() => {
                this.saving = false
                this.formControl.reset()
                this.notificationService.showSuccessMessage(MSG_PASSWORD_CHANGE_SUCCESSFUL)
              })
              .catch((error) => {
                if (error.code == 'auth/weak-password') {
                  this.notificationService.showErrorMessage(MSG_WEAK_PASSWORD)
                } else {
                  this.passwordChangeFailed()
                }
                this.saving = false
              })
          })
          .catch((error) => {
            if (error.code == 'auth/wrong-password') {
              this.notificationService.showErrorMessage(MSG_WRONG_PASSWORD)
            } else {
              this.passwordChangeFailed()
            }
            this.saving = false
          })
      })
  }

  private passwordChangeFailed() {
    this.saving = false
    this.notificationService.showErrorMessage(MSG_PASSWORD_CHANGE_FAILURE)
  }
}
