import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarComponent} from "../components/snackbar/snackbar.component";
import {SnackBarData} from "../dm";

const ERROR_CLASS = "snack-bar-error"
const WARNING_CLASS = "snack-bar-warning"
const SUCCESS_CLASS = "snack-bar-success"
const INFO_CLASS = "snack-bar-info"

@Injectable()
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar
  ) { }

  showErrorMessage(message: string, durationMs = 15_000) {
    this.showMessage(message, ERROR_CLASS, durationMs)
  }

  showSuccessMessage(message: string, durationMs = 15_000) {
    this.showMessage(message, SUCCESS_CLASS, durationMs)
  }

  showInfoMessage(message: string, durationMs = 10_000) {
    this.showMessage(message, INFO_CLASS, durationMs)
  }

  showWarningMessage(message: string, durationMs = 15_000) {
    this.showMessage(message, WARNING_CLASS, durationMs)
  }

  private showMessage(message: string, panelClass: string, durationMs: number) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: durationMs,
      panelClass: panelClass,
      data: {
        message: message
      } as SnackBarData
    })
  }
}
