import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {AccountService} from "../shared/services/account.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {NotificationService} from "../shared/services/notification.service";
import {HttpClient} from "@angular/common/http";

const MSG_PROFILE_PICTURE_CHANGED = $localize `:@@rest.profilePictureChanged:You've successfully changed your profile picture.`
const MSG_PROFILE_PICTURE_REMOVED = $localize `:@@rest.profilePictureRemoved:You've successfully removed your picture.`

@Component({
  selector: "profile-picture",
  styleUrls: ["profile-picture.component.scss"],
  templateUrl: "profile-picture.component.html"
})
export class ProfilePictureComponent implements OnInit{

  constructor(
    private readonly accountService: AccountService,
    private readonly sanitizer: DomSanitizer,
    private readonly notificationService: NotificationService,
    private readonly http: HttpClient
  ) { }

  @ViewChild("profilePictureCanvasElm") profilePictureCanvasElm!: ElementRef<HTMLCanvasElement>
  @ViewChild("profilePictureImgElm") profilePictureImgElm!: ElementRef<HTMLImageElement>

  saving = false
  removing = false

  hasDefaultProfilePicture: boolean = true
  currentProfilePictureUrl?: string
  selectedFile?: File
  selectedFileUrl?: SafeUrl

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0]

    if (this.selectedFile !== undefined) {
      const img = new Image
      const self = this
      img.onload = function() {
        self.renderImgToCanvas(img)
      }
      img.src = URL.createObjectURL(this.selectedFile);
    }
  }

  updateProfilePicture() {
    if (this.selectedFile == undefined) {
      return
    }

    const fileType = this.selectedFile.type

    this.selectedFile
      .arrayBuffer()
      .then(
        arrayBuffer => {
          const blob = new Blob([new Uint8Array(arrayBuffer)], {type: fileType})
          this.saving = true

          this.accountService.updateProfilePicture(blob).subscribe({
            next: () => {
              this.loadProfilePictureUrl()
              this.notificationService.showSuccessMessage(MSG_PROFILE_PICTURE_CHANGED)
              this.saving = false
            },
            error: () => this.saving = false
          })
        },
        error => {
          this.loadProfilePictureUrl()
        })
  }

  ngOnInit(): void {
    this.loadProfilePictureUrl()
  }

  profilePictureLoaded(event: any) {
    this.renderImgToCanvas(this.profilePictureImgElm.nativeElement)
  }

  removeProfilePicture() {
    this.removing = true

    this.accountService.deleteProfilePicture().subscribe({
      next: () => {
        this.loadProfilePictureUrl()
        this.notificationService.showSuccessMessage(MSG_PROFILE_PICTURE_REMOVED)
        this.removing = false
      },
      error: () => this.removing = false
    })
  }

  private loadProfilePictureUrl() {
    this.accountService
      .authenticate()
      .subscribe((authenticatedUser) => {
        // Blob is generated from the "fake" img tag that is hidden to user
        this.currentProfilePictureUrl = authenticatedUser.profilePictureUrl
        // Kind of hack - decide by "default" keyword in the profile picture URL
        this.hasDefaultProfilePicture = authenticatedUser.profilePictureUrl.indexOf("default") >= 0

        this.selectedFile = undefined
        this.selectedFileUrl = undefined
      })
  }

  private renderImgToCanvas(img: HTMLImageElement) {
    const canvas = this.profilePictureCanvasElm.nativeElement

    const maxSideSizePx = 200

    let widthPx = 0
    let heightPx = 0

    if (img.width > img.height) {
      if (img.width < maxSideSizePx) {
        widthPx = img.width
        heightPx = img.height
      } else {
        const scalingFactor = img.width / maxSideSizePx
        widthPx = img.width / scalingFactor
        heightPx = img.height / scalingFactor
      }
    } else {
      if (img.height < maxSideSizePx) {
        widthPx = img.width
        heightPx = img.height
      } else {
        const scalingFactor = img.height / maxSideSizePx
        widthPx = img.width / scalingFactor
        heightPx = img.height / scalingFactor
      }
    }


    canvas.width = widthPx
    canvas.height = heightPx

    const ctx = canvas.getContext("2d")
    if (ctx == null) {
      console.warn("Failed obtaining 2D context in canvas")
      return
    }

    ctx.drawImage(img, 0, 0, widthPx, heightPx)

    // Mask cropped parts
    ctx.fillStyle = "rgba(0, 0, 0, 0.7)"
    ctx.strokeStyle = "rgba(0, 0, 0, 0.7)"
    if (widthPx > heightPx) {
      const maskWidth = (widthPx - heightPx) / 2
      ctx.fillRect(0, 0, maskWidth, heightPx)
      ctx.fillRect(widthPx - maskWidth, 0, maskWidth, heightPx)
      ctx.strokeRect(maskWidth,0, heightPx, heightPx)
    } else {
      const maskHeight = (heightPx - widthPx) / 2
      ctx.fillRect(0, 0, widthPx, maskHeight)
      ctx.fillRect(0, heightPx - maskHeight, widthPx, maskHeight)
      ctx.strokeRect(0, maskHeight, widthPx, widthPx)
    }
  }
}
