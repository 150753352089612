import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map, Observable, switchMap, take, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {AuthService} from "./auth.service";
import {NotificationService} from "./notification.service";
import {AssamRestErrorResponse, LocusStore} from "../dm";
import PrePurchaseResponse = LocusStore.PrePurchaseResponse;
import PrePurchaseRequest = LocusStore.PrePurchaseRequest;
import PremiumVoucherRedeemRequest = LocusStore.PremiumVoucherRedeemRequest;
import PremiumVoucherRedeemResponse = LocusStore.PremiumVoucherRedeemResponse;
import ResponseStatus = LocusStore.ResponseStatus;
import VoucherConsumeResponse = LocusStore.VoucherConsumeResponse;
import VoucherDetailResponse = LocusStore.VoucherDetailResponse;
import VoucherDetail = LocusStore.VoucherDetail;
import PrePurchaseResultParams = LocusStore.PrePurchaseResultParams;
import UserPremiumSubscriptions = LocusStore.UserPremiumSubscriptions;
import VoucherConsumeDetail = LocusStore.VoucherConsumeDetail;
import PremiumItem = LocusStore.PremiumItem;
import PremiumItemsResponse = LocusStore.PremiumItemsResponse;

const ERR_MSG_SOMETHING_WENT_WRONG = $localize `:@@rest.errorMsg.generic:Sorry, something went wrong with your request. Please, try again later.`
const ERR_MSG_401_NOT_AUTHORIZED = $localize `:@@rest.errorMsg.401:Sorry, you can't do this without authorization.`
const ERR_MSG_404_NOT_FOUND = $localize `:@@rest.errorMsg.404:Sorry, can't find what you're looking for.`

const ERR_MSG_UPDATING_USER_PROFILE_FAILED
  = $localize `:@@rest.redeemingVoucherFailed:Sorry, failed to redeem the voucher. Please, try again later.`

const AUTH_TOKEN_HEADER = "X-Asamm-Service-AuthToken"
const AUTHORIZATION_HEADER = "Authorization"
const HEADER_APP_VARIANT = "X-Asamm-App-Variant"
const HEADER_APP_VARIANT_ACCOUNT_SERVICE_UI = "20000"
const HEADER_APP_VERSION = "X-Asamm-App-Version"

const RESPONSE_STATUS_SUCCESS = 20_001

class LocusStoreResponseError extends Error {
  constructor(message: string) {
    super(message)
  }
}

@Injectable()
export class LocusStoreService {
  private readonly baseUrl = environment.asamm.locusStoreUrl

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    @Inject(LOCALE_ID) private readonly locale: string,
  ) { }

  createHeaders(tokenId: string): HttpHeaders {
    return new HttpHeaders()
      // .set("Content-Type", "application/json")
      // .set("Accept", "application/json")
      .set(AUTHORIZATION_HEADER, `Bearer ${tokenId}`)
      .set(HEADER_APP_VARIANT, HEADER_APP_VARIANT_ACCOUNT_SERVICE_UI)
      .set(HEADER_APP_VERSION, "-1") // Temporary hack, not needed right now
  }

  loadUserProfile(): Observable<LocusStore.UserProfile> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        return this.httpClient.get(
          `${this.baseUrl}/store/rest/users/profile`,
          { headers: this.createHeaders(token) }
        ).pipe(
          take(1),
          map((result) => {
            const response = result as LocusStore.UserProfileResponse
            this.throwErrrorIfResponseStatusIsNotSuccess(response)

            return response.userProfile
          })
        )
      }),
      catchError(error => {
        this.handleErrorResponse(error)
        return throwError(() => error)
      })
    )
  }

  loadVoucherDetail(voucher: string): Observable<VoucherDetail> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        return this.httpClient.get(
          `${this.baseUrl}/store/rest/vouchers/${voucher}`,
          { headers: this.createHeaders(token) }
        ).pipe(
          take(1),
          map((result) => {
            const response = result as VoucherDetailResponse
            this.throwErrrorIfResponseStatusIsNotSuccess(response)

            return response.voucherDetail
          })
        )
      }),
      catchError(error => {
        this.handleErrorResponse(error)
        return throwError(() => error)
      })
    )
  }

  prepurchaseVoucher(productId: string): Observable<PrePurchaseResultParams> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        const request: PrePurchaseRequest = {
          productId: productId
        }

        return this.httpClient.post(
          `${this.baseUrl}/store/rest/premium/purchase/preregistration`,
          request,
          { headers: this.createHeaders(token) }
        ).pipe(
          take(1),
          map((result) => {
            const response = result as PrePurchaseResponse
            this.throwErrrorIfResponseStatusIsNotSuccess(response)

            return response.prePurchaseResultParams
          })
        )
      }),
      catchError(error => {
        this.handleErrorResponse(error)
        return throwError(() => error)
      })
    )
  }

  loadPremiumItems(
    premiumItemIds: string[] // productIds
  ): Observable<PremiumItem[]> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        const urlParams = premiumItemIds
          .map((id) => `itemid=${id}`)
          .join("&")

        return this.httpClient.get(
          `${this.baseUrl}/store/rest/premium/items/search?${urlParams}`,
          { headers: this.createHeaders(token) }
        ).pipe(
          take(1),
          map((result) => {
            const response = result as PremiumItemsResponse
            this.throwErrrorIfResponseStatusIsNotSuccess(response)

            return response.items
          })
        )
      }),
      catchError(error => {
        this.handleErrorResponse(error)
        return throwError(() => error)
      })
    )
  }

  redeemVoucher(
    voucher: string,
    // Value taken from pre-purchase response
    payload: string
  ): Observable<UserPremiumSubscriptions> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        const request: PremiumVoucherRedeemRequest = {
          voucherCode: voucher,
          payload: payload,
          packageName: "com.asamm.accountservice.ui"
        }

        return this.httpClient.post(
          `${this.baseUrl}/store/rest/premium/voucher`,
          request,
          { headers: this.createHeaders(token) }
        ).pipe(
          take(1),
          map((result) => {
            const response = result as PremiumVoucherRedeemResponse
            this.throwErrrorIfResponseStatusIsNotSuccess(response)

            return response.userPremiumSubscriptions
          })
        )
      }),
      catchError(error => {
        this.handleErrorResponse(error)
        return throwError(() => error)
      })
    )
  }

  applyLocoinVoucher(voucher: string): Observable<VoucherConsumeDetail> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        return this.httpClient.post(
          `${this.baseUrl}/store/rest/vouchers/${voucher}`,
          null,
          { headers: this.createHeaders(token) }
        ).pipe(
          take(1),
          map((result) => {
            const response = result as VoucherConsumeResponse
            this.throwErrrorIfResponseStatusIsNotSuccess(response)

            return response.voucherConsumeDetail
          })
        )
      }),
      catchError(error => {
        this.handleErrorResponse(error)
        return throwError(() => error)
      })
    )
  }

  private throwErrrorIfResponseStatusIsNotSuccess(response: ResponseStatus) {
    switch (response.responseStatus.code) {
      case 40313:
        throw new LocusStoreResponseError($localize `:@@rest.locusStore.voucherAlreadyConsumed:This voucher has already been redeemed. Try another one.`)
      case 40265:
        throw new LocusStoreResponseError($localize `:@@rest.locusStore.cantConsumeMoreThanHalfRemaining:Can't redeem the voucher. There is still more than 50% of your existing Premium Plan remaining.`)
    }

    if (response.responseStatus.code !== RESPONSE_STATUS_SUCCESS) {
      throw new LocusStoreResponseError(`${response.responseStatus.message} (Error code: ${response.responseStatus.code})`)
    }
  }

  // TODO extract to separate service (similar impl in account.service.ts)
  // Copied from web planner project
  private handleErrorResponse(errorResponse: any, specificErrMessage = ERR_MSG_SOMETHING_WENT_WRONG) {
    const self = this

    function handleErrorNotificationForHttpErrorCode() {
      switch (errorResponse.status as number) {
        case 401:
          self.notificationService.showErrorMessage(ERR_MSG_401_NOT_AUTHORIZED)
          break
        case 404:
          self.notificationService.showErrorMessage(ERR_MSG_404_NOT_FOUND)
          break
        default:
          self.notificationService.showErrorMessage(specificErrMessage)
      }
    }

    // Handle generic error message
    if (errorResponse instanceof LocusStoreResponseError) {
        this.notificationService.showErrorMessage(errorResponse.message)
      return
    }

    if (errorResponse?.error?.errorCode === undefined
      || errorResponse?.error?.errorCode === null
      || typeof errorResponse.error.errorCode !== 'string') {

      handleErrorNotificationForHttpErrorCode()

      return
    }

    let assamRestError = errorResponse.error as AssamRestErrorResponse
    switch (assamRestError.errorCode) {
      case "VALIDATION_INVALID_DATA":
        const validationErrMsgs = assamRestError.errors.map(e => "* " + e.message).join("\n")
        self.notificationService
          .showErrorMessage(`${assamRestError.message}\n${validationErrMsgs}`)
        return;
    }

    handleErrorNotificationForHttpErrorCode()
  }
}
