import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {AppMode} from "../dm";

@Injectable()
export class SettingsService {
  private readonly menuVisibleSubject = new BehaviorSubject<boolean>(true)
  readonly menuVisible$ = this.menuVisibleSubject.asObservable()

  private readonly modeSubject = new BehaviorSubject<AppMode>(AppMode.WEB)
  readonly mode$ = this.modeSubject.asObservable()

  setMenuVisible(visible: boolean) {
    this.menuVisibleSubject.next(visible)
  }

  setAppMode(mode: AppMode) {
    this.modeSubject.next(mode)
  }
}
