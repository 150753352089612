import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, delay, map, Observable, of, switchMap, take, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {AuthService} from "./auth.service";
import {NotificationService} from "./notification.service";
import {AssamRestErrorResponse, AuthenticatedUser, ListOption, UserProfile, UserProfileUpdate} from "../dm";

const ERR_MSG_SOMETHING_WENT_WRONG = $localize `:@@rest.errorMsg.generic:Sorry, something went wrong with your request. Please, try again later.`
const ERR_MSG_401_NOT_AUTHORIZED = $localize `:@@rest.errorMsg.401:Sorry, you can't do this without authorization.`
const ERR_MSG_404_NOT_FOUND = $localize `:@@rest.errorMsg.404:Sorry, can't find what you're looking for.`
const ERR_MSG_FIREBASE_EXPIRED_ID_TOKEN = $localize `:Display error message when user is communicating with backend using expired idToken from Firabse.@@rest.firebase.errorMsg.tokenExpired:Your session has expired. Please refresh the page or log in again.`
const ERR_MSG_REQUEST_ALREADY_IN_PROGRESS = $localize `:Displayed error message when user have pending request and try to send another one.@@rest.errorMsg.requestAlreadyInProgress:Your request is already being processed. You cannot make another request at this time.`

const ERR_MSG_UPDATING_USER_PROFILE_FAILED
  = $localize `:@@rest.updatingProfileFailed:Sorry, your profile update somehow failed. Please, try again later.`
const ERR_MSG_DELETING_USER_PROFILE_PICTURE_FAILED
  = $localize `:@@rest.avatarDeleteFailed:Sorry, deleting your picture somehow failed. Please, try again later.`
const ERR_MSG_ACCOUNT_DELETE_REQUEST_FAILED
  = $localize `:@@rest.accountDeleteRequestFailed:Apologies, there was an issue deleting your account. Please try again later.`
const ERR_MSG_USER_ACCOUNT_DELETED
  = $localize`:@@rest.accountDeletionScheduledOrDeleted:Your account deletion request has been received. Your account is either scheduled for deletion or already deleted. If this was a mistake, please contact our support team immediately for assistance.`
const AUTH_TOKEN_HEADER = "X-Asamm-Service-AuthToken"

@Injectable()
export class AccountService {
  private readonly baseUrl = environment.asamm.accountServiceUrl
  private readonly accountServiceAuthToken = environment.asamm.accountServiceAuthToken

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    @Inject(LOCALE_ID) private readonly locale: string,
  ) { }

  authenticate(): Observable<AuthenticatedUser> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        let headers = new HttpHeaders()
          // .set("Content-Type", "application/json")
          // .set("Accept", "application/json")
          .set(AUTH_TOKEN_HEADER, this.accountServiceAuthToken)

        let body = {
          idToken: token
        }

        return this.httpClient.post(
          `${this.baseUrl}/api/v1/authenticate/firebase/idtoken`,
          body,
          { headers: headers }
        ).pipe(
          take(1),
          map((user) => user as AuthenticatedUser)
        )
      }),
      catchError(error => {
        this.handleErrorResponse(error)
        return throwError(error)
      })
    )
  }

  loadUserProfile(): Observable<UserProfile> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        let requestUrl = `${this.baseUrl}/api/v1/user/profile`

        return this.httpClient
          .get(requestUrl, {
            headers: {
              "Authorization": `Bearer ${token}`
            }
          })
          .pipe(
            map(result => result as UserProfile)
          )
      }),
      catchError(error => {
        this.handleErrorResponse(error)
        return throwError(error)
      })
    )
  }

  updateUserProfile(userProfile: UserProfileUpdate): Observable<void> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        return this.httpClient
          .patch(`${this.baseUrl}/api/v1/user/profile`,
            userProfile, {
              headers: {
                "Authorization": `Bearer ${token}`
              }
            }) as Observable<any>
      }),
      catchError(error => {
        this.handleErrorResponse(error, ERR_MSG_UPDATING_USER_PROFILE_FAILED)
        return throwError(error)
      })
    )
  }

  updateProfilePicture(img: Blob): Observable<void> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        return this.httpClient
          .post(`${this.baseUrl}/api/v1/user/profile-picture`,
            img, {
              headers: {
                "Authorization": `Bearer ${token}`
              }
            }) as Observable<any>
      }),
      catchError(error => {
        this.handleErrorResponse(error, ERR_MSG_UPDATING_USER_PROFILE_FAILED)
        return throwError(error)
      })
    )
  }

  deleteProfilePicture(): Observable<void> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        return this.httpClient
          .delete(`${this.baseUrl}/api/v1/user/profile-picture`, {
              headers: {
                "Authorization": `Bearer ${token}`
              }
            }) as Observable<any>
      }),
      catchError(error => {
        this.handleErrorResponse(error, ERR_MSG_DELETING_USER_PROFILE_PICTURE_FAILED)
        return throwError(error)
      })
    )
  }

  loadCountries(): Observable<ListOption[]> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        let requestUrl = `${this.baseUrl}/api/v1/country/list/${this.locale}`

        return this.httpClient
          .get(requestUrl, {
            headers: {
              "Authorization": `Bearer ${token}`
            }
          })
          .pipe(
            map(result => result as ListOption[])
          )
      }),
      catchError(error => {
        this.handleErrorResponse(error)
        return throwError(error)
      })
    )
  }

  loadLanguages(): Observable<ListOption[]> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        let requestUrl = `${this.baseUrl}/api/v1/language/list`

        return this.httpClient
          .get(requestUrl, {
            headers: {
              "Authorization": `Bearer ${token}`
            }
          })
          .pipe(
            map(result => result as ListOption[])
          )
      }),
      catchError(error => {
        this.handleErrorResponse(error)
        return throwError(error)
      })
    )
  }

  requestAccountDeletion(reason: string): Observable<void> {
    return this.authService.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token === undefined) {
          return throwError(() => "Auth token is required!")
        }

        return this.httpClient
          .post(`${this.baseUrl}/api/v1/user/request-account-deletion`,
            {
              reason: reason
            },
            {
              headers: {
                "Authorization": `Bearer ${token}`
              }
            }) as Observable<any>
      }),
      catchError(error => {
        this.handleErrorResponse(error, ERR_MSG_ACCOUNT_DELETE_REQUEST_FAILED)
        return throwError(error)
      })
    )
  }

  // Copied from web planner project
  private handleErrorResponse(errorResponse: any, specificErrMessage = ERR_MSG_SOMETHING_WENT_WRONG) {
    const self = this

    function handleErrorNotificationForHttpErrorCode() {
      switch (errorResponse.status as number) {
        case 401:
          self.notificationService.showErrorMessage(ERR_MSG_401_NOT_AUTHORIZED)
          break
        case 404:
          self.notificationService.showErrorMessage(ERR_MSG_404_NOT_FOUND)
          break
        default:
          self.notificationService.showErrorMessage(specificErrMessage)
      }
    }

    if (errorResponse?.error?.errorCode === undefined
      || errorResponse?.error?.errorCode === null
      || typeof errorResponse.error.errorCode !== 'string') {

      handleErrorNotificationForHttpErrorCode()

      return
    }

    let assamRestError = errorResponse.error as AssamRestErrorResponse
    switch (assamRestError.errorCode) {
      case "VALIDATION_INVALID_DATA":
        const validationErrMsgs = assamRestError.errors.map(e => "* " + e.message).join("\n")
        self.notificationService
          .showErrorMessage(`${assamRestError.message}\n${validationErrMsgs}`)
        return;
      case "FIREBASE_ID_TOKEN_INVALID":
        self.notificationService.showErrorMessage(ERR_MSG_FIREBASE_EXPIRED_ID_TOKEN)
        return;
      case "REQUEST_ALREADY_IN_PROGRESS":
        self.notificationService.showErrorMessage(ERR_MSG_REQUEST_ALREADY_IN_PROGRESS)
        return;
      case "USER_ACCOUNT_DELETED":
        self.notificationService.showErrorMessage(ERR_MSG_USER_ACCOUNT_DELETED, 60_000)
        return;
      default:
        handleErrorNotificationForHttpErrorCode()
    }
  }
}
